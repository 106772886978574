import "./App.css";
import SignInScreen from "./firebase";
import React, { useEffect, useState } from "react";
import firebase from 'firebase/compat/app';
import { Form, Input, Button, Alert, Card } from "antd";
import { SendOutlined, LogoutOutlined } from "@ant-design/icons";
import axios from "axios";
import playerHubLogo from "./playerhub.png";

const API_BASE = "https://playerhub-api.curseofaros.com";
// const API_BASE = "http://localhost:4000";

const App = (props) => {
    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);

    const logOut = () => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                setUser(null);
                setAccount(null);
            });
    };

    useEffect(() => {
        return firebase.auth().onAuthStateChanged((user) => {
            setUser(user);
            refresh(user, null, setAccount);
        });
    }, []);

    return (
        <div className="App">
            <Alert
                message={
                    <span>
                        <b>Welcome!</b> The PlayerHub app is an experimental way to self-manage your Curse of Aros account.
                        <br />
                        It is in incubation and still in development, and is currently only for certain official events.
                    </span>
                }
                type="info"
            />

            <div style={{ marginTop: 8, textAlign: "right", marginRight: 8 }}>
                <Button
                    icon={<LogoutOutlined />}
                    danger
                    disabled={!user}
                    onClick={logOut}
                >
                    Sign Out
                </Button>
            </div>

            <header className="App-header" style={{ marginTop: 24 }}>
                <img src={playerHubLogo} height="60" style={{ marginBottom: 24 }} alt="" />

                {!user ? (
                    <Card bordered={true}>
                        <h2>Sign In with your Curse of Aros account.</h2>
                        <p>Use the same Google or Apple account as you use when playing.</p>

                        <SignInScreen callback={(token) => console.log(token)} />
                    </Card>
                ) :
                    <Alert
                        message={
                            <span>You are logged in as <b>{account ? account.name : 'idk'}</b>.</span>
                        }
                        type="success"
                        showIcon
                        style={{ marginBottom: 16 }}
                    />}

                {user ? (
                    <Card bordered={true}>
                        <NameEditor user={user} />
                    </Card>
                ) : null}
            </header>

            <p style={{ marginTop: 8 }}>
                <i>Psst, guild leaders!</i>{"  "}Ensure all your members fill out the form to ensure your guild is all set!
            </p>
        </div>
    );
}

function refresh(user, setReg, setAccount) {
    user.getIdToken(false).then((token) => {
        axios
            .get(API_BASE + "/api/v1/guilds/status", { headers: { Authorization: "Bearer " + token } })
            .then((r) => {
                const account = r.data.account;

                setReg && setReg(r.data.prereg);

                setAccount({
                    id: account.account_id,
                    characterId: account.character_id,
                    name: account.name,
                    nameColor: account.name_color,
                });
            });
    });
}

const NameEditor = ({ user }) => {
    const [account, setAccount] = useState(null);
    const [reg, setReg] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [submitAlert, setSubmitAlert] = useState(null);

    useEffect(() => {
        refresh(user, setReg, setAccount);
    }, [user, setReg, setAccount]);

    return (
        <div style={{ maxWidth: 600 }}>
            <h2>Guild Registration</h2>

            <p><b>Are you in a guild?</b><br />With the release of the Guild system on the horizon, we want to ensure we know who belongs to which guild.</p>
            <p><b>Not in a guild?</b> Simply close this page; you don't need to do anything!</p>

            {user && account ? (
                <div>

                    {submitAlert && (
                        <Alert
                            style={{ marginTop: 8 }}
                            closable={true}
                            onClose={() => setSubmitAlert(null)}
                            message={submitAlert.message}
                            type={submitAlert.type}
                            showIcon
                        />
                    )}

                    <Form
                        style={{ marginTop: 32 }}
                        name="basic"
                        layout="horizontal"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={reg ? { name: reg.guild_name, tag: reg.guild_tag, leader: reg.owner_name } : {}}
                        onFinish={(a) => {
                            console.log(a);

                            if (!submitting) {
                                setSubmitting(true);
                                setSubmitAlert(null);

                                user.getIdToken(true).then((token) => {
                                    axios
                                        .post(
                                            API_BASE + "/api/v1/guilds/status",
                                            a,
                                            { headers: { Authorization: "Bearer " + token } }
                                        )
                                        .then((r) => {
                                            console.log(r.data);

                                            setSubmitting(false);
                                            setSubmitAlert({ type: "success", message: r.data });
                                            refresh();
                                        })
                                        .catch((err) => {
                                            setSubmitting(false);
                                            console.log(err.response.data);

                                            setSubmitAlert({ type: "error", message: err.response.data });
                                        });
                                });
                            }
                        }}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Guild tag"
                            name="tag"
                            required
                            tooltip="This can be the same as your Guild name, or a tag you all use to identify each other."
                            rules={[{ required: true, message: "Please enter your guild's tag", pattern: /^[a-zA-Z0-9]+$/ }]}
                        >
                            <Input minLength={2} maxLength={6} prefix="#" placeholder="e.g. COA, AROS, WTFBBQ, LGBTQWERTY, etc" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                        </Form.Item>

                        <Form.Item
                            label="Guild name"
                            name="name"
                            required
                            tooltip="Some guilds have a 'full' name. If yours does not, just enter the tag here too."
                            rules={[{ required: true, message: "Please enter your guild name (or tag)" }]}
                        >
                            <Input minLength={2} maxLength={64} placeholder='This is most likely the "full" guild name, or the tag.' />
                        </Form.Item>

                        <Form.Item
                            label="Guild leader"
                            name="leader"
                            required
                            tooltip="Who is leading the guild? Enter their in-game name here. If you are the leader or unsure, enter your own."
                            rules={[{ required: true, message: "Please enter the leader's in-game name" }]}
                        >
                            <Input minLength={2} maxLength={64} placeholder='The in-game name of the leader.' />
                        </Form.Item>

                        <Form.Item wrapperCol={{}}>
                            <Button icon={<SendOutlined />} loading={submitting} type="primary" htmlType="submit">
                                Update my status
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            ) : null}
        </div>
    );
};


export default App;
