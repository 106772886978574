import firebase from 'firebase/compat/app';
import { GoogleAuthProvider, getAuth, signInWithCustomToken } from 'firebase/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const firebaseConfig = {
    apiKey: "AIzaSyATK-24n7jWqMtghxb-HtogEZccJ8_NpWQ",
    authDomain: "curse-of-aros.firebaseapp.com",
    databaseURL: "https://curse-of-aros.firebaseio.com",
    projectId: "curse-of-aros",
    storageBucket: "curse-of-aros.appspot.com",
    messagingSenderId: "216084705424",
    appId: "1:216084705424:web:e514f00d9d8a04d3"
};

firebase.initializeApp(firebaseConfig);

// Get token from query parameter
const token = new URLSearchParams(window.location.search).get('token');

if (token) {
    signInWithCustomToken(getAuth(), token).then(userCredential => {
        // Signed in
        const user = userCredential.user;
        console.log("User", user);
    });
}

function SignInScreen({ callback }) {
    return (
        <StyledFirebaseAuth uiConfig={{
            signInFlow: 'popup',
            signInOptions: [
                {
                    provider: GoogleAuthProvider.PROVIDER_ID, customParameters: {
                        prompt: 'select_account'
                    }
                },
                'apple.com',
            ],
            callbacks: {
                signInSuccessWithAuthResult: function (r) {
                    r.user.getIdToken().then(function (idToken) {
                        callback(idToken)
                    }).catch(function (error) {
                        console.error(error);
                    });
                }
            }
        }} firebaseAuth={getAuth()} />
    );
}

export default SignInScreen